<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Crear zona
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="createZone">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <!-- col 1 -->
            <b-col sm="6" class="product_info">
              <!-- name -->
              <validation-provider #default="{ errors }" :name="'Nombre'" rules="required">
                <b-form-group :label="'Nombre'" label-for="product-name">
                  <b-form-input v-model="name" name="name" :placeholder="'Nombre'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- description -->
              <b-form-group :label="'Descripción'" label-for="product-description">
                <quill-editor v-model="description" />
              </b-form-group>

            </b-col>
            <!-- col 2 -->
            <b-col sm="6" class="product_info">

            </b-col>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1 text-uppercase">
                {{ 'Crear Zona' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    ImageDropzone,
  },
  data() {
    return {
      project_id: this.$route.params.project_id,
      name: '',
      description: '',

    }
  },
  computed: {
    ...mapGetters({

    }),
  },
  methods: {
    ...mapActions({
      create: 'locations/create',

    }),
    handleSubmit() {
      this.$refs.createZone.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ location: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormData() {
      const data = new FormData()

      data.append('name', this.name)

      if (this.description !== '' || this.description !== null) {
        data.append('description', this.description)
      }

      data.append('project_id', this.project_id)
      data.append('product_id', null)

      // console.log('creando...', this)

      return data
    },

  },
  async created() {

    console.log('crear zona en obra...', this.project_id)
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
